<div class="mobile">
  <div id="hamburger" (click)="openMenu()">
    <span></span>
  </div>
  <img src="../assets/images/logo-dev.png" alt="Curam logo">
</div>
<main>
<div id="menu" [ngClass]="showMenu ? 'toggled' : ''">
  <app-sidebar></app-sidebar>
</div>
<div class="box-wrapper">
  <router-outlet></router-outlet>
</div>
</main>
