   
      <nav>
        <span id="close">&times;</span>
        <img class="logo" src="../../../../assets/images/logo.png" alt="Curam logo">

        <a href="#" class="thin profiler">
          <img src="../../../../assets/images/client-view/user-lg.png" alt="User profiler" class="profile-img">
          <span>Hi, Jody C.</span>
        </a>

        <a href="#" class="thin">My profile</a>
        <a href="#" class="thin">Edit profile</a>
        <hr>
        <a href="#">Dashboard</a>
        <a href="#">Messages</a>
        <a href="#">Shift invoices</a>
        <a href="#">Agreements</a>
        <a href="#">My jobs & applicants</a>
        <a href="#">Post a job</a>
        <a href="#">Find a carer</a>
        <a href="#">Favourite carer</a>
      </nav>

      <nav>
        <a href="#" class="thin">Helpdesk</a>
        <a href="#" style="font-size: 18px">Logout</a>
      </nav> 
