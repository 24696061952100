import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MessageService } from './Services/message.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule,HttpClientModule],
  exports: [SidebarComponent],
})
export class CommonsModule {}
